import React from 'react';
import { normalizedContains } from 'shared/utils';
import styles from './forms.module.scss';
import { ConfigProvider, Select as SelectAnt, Tag } from 'antd';
import { antdTheme as theme, colorPalette } from 'shared/constants';
import _ from 'lodash';

/**
 * @typedef {import('antd').SelectProps
 *  & {
 *      name: string,
 *      multiple: boolean,
 *      label: JSX.Element,
 *      error: string,
 *      value: any,
 *      onChange: (name: string, value: any) => void | Promise<void>
 *      borderRadius: number
 * }} SelectProps
 */

const Select = React.memo(
  /**
   *
   * @param {SelectProps} param0
   * @returns
   */
  function SelectBox({
    label = null,
    value,
    onChange,
    name = '',
    placeholder = '',
    error = null,
    disabled = false,
    multiple = false,
    borderRadius = 8,
    ...props
  }) {
    const handleOnChange = value => {
      onChange(name, value);
    };

    const tagRender = props => {
      const { label, value, closable, onClose } = props;
      const onPreventMouseDown = event => {
        event.preventDefault();
        event.stopPropagation();
      };
      return (
        <Tag
          onMouseDown={onPreventMouseDown}
          closable={closable}
          onClose={onClose}
          style={{ margin: 3, whiteSpace: 'pre-wrap' }}
        >
          {label || value}
        </Tag>
      );
    };

    return (
      <ConfigProvider
        theme={{
          components: {
            Select: {
              borderRadius,
              colorBorder: theme.colorBorder,
              colorTextPlaceholder: theme.colorTextPlaceholder,
              multipleItemBorderColor: theme.colorBorder,
              multipleItemBg: colorPalette.white,
            },
            Tag: {
              defaultBg: colorPalette.primaryMain1,
              colorBorder: colorPalette.primaryMain5,
              lineHeightSM: 2,
            },
          },
        }}
      >
        <div className={styles.root}>
          {label && <label>{label}</label>}
          <SelectAnt
            mode={multiple && 'multiple'}
            value={!_.isEmpty(value) ? value : undefined}
            error={error}
            status={error && 'error'}
            placeholder={placeholder}
            name={name}
            onChange={handleOnChange}
            filterOption={(inputValue, option) => normalizedContains(option?.label, inputValue)}
            disabled={disabled}
            showSearch
            allowClear
            tagRender={tagRender}
            defaultValue={value}
            {...props}
          />
          <span className={styles.error}>{error}</span>
        </div>
      </ConfigProvider>
    );
  },
);

export { Select };
