import {
  WORKSPACES_RESTRICT_WORKSPACE,
  WORKSPACES_SELECT_WORKSPACE,
  WORKSPACES_UPDATE_CONTENT,
} from '.';
import workspaceServiceApi from '../../api/workspace.service.api';
import { applicationRefreshDate } from './application.actions';

export const fetchWorkspaces = () => async dispatch => {
  const { error, data } = await workspaceServiceApi.getWorkspaces();

  if (error) {
    console.error('Failed to fetch workspaces');
    return { error: data.message };
  }

  dispatch({
    type: WORKSPACES_UPDATE_CONTENT,
    payload: { workspaces: data },
  });
};

export const selectWorkspace = workspaceId => async dispatch => {
  await workspaceServiceApi.selectWorkspace({ workspaceId });

  dispatch({
    type: WORKSPACES_SELECT_WORKSPACE,
    payload: { selected: workspaceId },
  });
};

export const createWorkspace =
  ({ name, organizationId, operational }) =>
  async dispatch => {
    const { error, data } = await workspaceServiceApi.createWorkspace({
      name,
      organizationId,
      operational,
    });

    if (error) {
      console.error('Failed to create workspace');
      return { error: data.message };
    }

    dispatch(applicationRefreshDate());

    return data;
  };

export const updateWorkspace =
  ({ workspaceId, name, operational = undefined }) =>
  async dispatch => {
    const { error, data } = await workspaceServiceApi.updateWorkspace({
      workspaceId,
      name,
      operational,
    });

    if (error) {
      console.error('Failed to update workspace');
      return { error: data.message };
    }

    await dispatch(fetchWorkspaces());

    return data;
  };

export const restrictWorkspace =
  ({ workspaceId, restricted }) =>
  async dispatch => {
    const { error, data } = await workspaceServiceApi.restrictWorkspace({
      workspaceId,
      restricted,
    });

    if (error) {
      console.error('Failed to restrict workspace');
      return { error: data.message };
    }

    await dispatch({
      type: WORKSPACES_RESTRICT_WORKSPACE,
      payload: { workspaceId, restricted },
    });

    return data;
  };
