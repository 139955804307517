import userService from '../../services/user.service';
import {
  APPLICATION_REFRESH_DATE,
  APP_IS_READY,
  AUTH_LOGIN,
  AUTH_UPDATE_TOKEN,
  LAB_DELETE_CATEGORY,
  LAB_UPDATE_CATEGORY,
  OWNERS_TRANSFER_OWNERSHIP,
} from '../actions';
import { getLabData } from '../actions/lab.actions';

export const labFetchDataMiddleware =
  ({ dispatch }) =>
  next =>
  async action => {
    next(action);

    if (
      action.type === AUTH_LOGIN ||
      action.type === APP_IS_READY ||
      action.type === AUTH_UPDATE_TOKEN ||
      action.type === APPLICATION_REFRESH_DATE ||
      action.type === OWNERS_TRANSFER_OWNERSHIP
    ) {
      if (!userService.isAuthenticated) return false;

      dispatch(getLabData());
    }
  };

export const labOnCategoryChangeOwnerIdMiddleware =
  ({ dispatch }) =>
  next =>
  async action => {
    next(action);

    if (action.type === LAB_UPDATE_CATEGORY) {
      if (!action.payload.ownerIds) return false;

      dispatch(getLabData());
    }
  };

export const labOnCategoryDeleteMiddleware =
  ({ dispatch }) =>
  next =>
  async action => {
    next(action);

    if (action.type === LAB_DELETE_CATEGORY) {
      dispatch(getLabData());
    }
  };

export const labMiddleware = [
  labFetchDataMiddleware,
  labOnCategoryChangeOwnerIdMiddleware,
  labOnCategoryDeleteMiddleware,
];
