import React from 'react';
import { useRotationsContext } from '../rotations-context';
import { Button, TextInput } from 'src/components/design-system';
import styles from './rotations-list.module.scss';
import { Modal, notification } from 'antd';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import rotationsServiceApi from 'shared/api/rotations.service.api';
import { MultipleOwnersSelector } from 'src/components/owners/owners-selector/multiple-owners-selector';

const CREATE_ROTATION_SCHEMA = Yup.object().shape({
  name: Yup.string().required('Required!').min(3, 'Too Short!').max(100, 'Too Long!'),
  code: Yup.string().required('Required!').min(1, 'Too Short!').max(10, 'Too Long!'),
  ownerIds: Yup.array().of(Yup.string()).min(1, 'Required!'),
});

const AddNewRotation = () => {
  const { lang, fetchRotations, setRotationId } = useRotationsContext();
  const [addNewRotationModal, setAddNewRotationModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [notificationApi, contextHolder] = notification.useNotification({
    placement: 'bottomRight',
  });

  const createNewRotation = async values => {
    setLoading(true);

    const { data, error } = await rotationsServiceApi.createRotation(values);

    if (error) {
      notificationApi.error({
        message: lang.FAIL_TO_CREATE_ROTATION,
      });
      setLoading(false);
      return;
    }

    notificationApi.success({
      message: lang.ROTATION_CREATED,
    });

    // Fetch rotations to update the list
    await fetchRotations({ showLoading: false });

    // Set the rotation id
    setRotationId(data.id);

    setAddNewRotationModal(false);
    setLoading(false);

    // reset form
    resetForm();
  };

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    values,
    errors,
    isValid,
    resetForm,
  } = useFormik({
    initialValues: {
      name: '',
      code: '',
      ownerIds: [],
    },
    validationSchema: CREATE_ROTATION_SCHEMA,
    onSubmit: createNewRotation,
  });

  const setCode = e => {
    const { value, name } = e.target;
    setFieldValue(name, value.toUpperCase().trim());
  };

  return (
    <div className={styles.addRotation}>
      <Button onClick={() => setAddNewRotationModal(true)}>{lang.ADD_NEW_ROTATION}</Button>
      <Modal
        title={lang.ADD_NEW_ROTATION}
        open={addNewRotationModal}
        onOk={handleSubmit}
        okButtonProps={{
          loading: loading,
          disabled: !isValid,
        }}
        cancelButtonProps={{
          disabled: loading,
        }}
        centered
        onCancel={() => setAddNewRotationModal(false)}
      >
        <form onSubmit={handleSubmit}>
          <label className={styles.editNameLabel}>{lang.ROTATION_NAME}</label>
          <TextInput
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            size="large"
            name="name"
            error={errors.name}
          />

          <label className={styles.editNameLabel}>{lang.ROTATION_CODE}</label>
          <TextInput
            value={values.code}
            onChange={setCode}
            onBlur={handleBlur}
            size="large"
            name="code"
            error={errors.code}
          />

          <label>{lang.ROTATION_DIRECTOR}</label>
          <div>
            <MultipleOwnersSelector
              size="large"
              bordered
              multiple
              name="ownerIds"
              errors={errors.ownerIds}
              onChange={(f, v) => setFieldValue(f, v)}
              value={values.ownerIds}
            />
          </div>
        </form>
      </Modal>
      {contextHolder}
    </div>
  );
};

export { AddNewRotation };
