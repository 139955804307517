export const colors = {
  black: '#000000',
  white: '#ffffff',
  peachyPink: '#ff8080',
  veryLightGray: '#c3c3c3',
  veryLightGray2: '#e8e8e8',
  veryLightGray3: '#e3e3e3',
  darkGrey: '#aaaaaa',
  darkSlateBlue: '#193f6a',
  opacityDarkSlateBlue: '#6f89a6',
  LighterGray: '#fafafa',
  lightGray: '#f8f8f8',
  lightGray2: '#f5f5f5',
  lightDarkGray: '#f0f0f0', //mobile screen background
  greenishCyan: '#34f5cf',
  greenishCyanTwo: '#31f4cf',
  brownGrey: '#989898',
  lightSeaGreen: '#2ecc71',

  primary: '#193f6a',

  veryLightBlue: '#edf7ff',
  veryLightBlue2: '#dbf0ff',
  iconBlue: '#2c5789',
  linkBlue: '#a0e1f6',
  primaryBlue: '#1677ff',
  fadedText: '#999999',
  grayDark: '#5d5d5d',
  grayMediumLight: '#e1e1e1',
  colorText: '#333',
};

export const theme = {
  fontFamily: 'Inter',
  newFontFamily: 'Inter, sans-serif',
  pageBackground: colors.white,
  fontColor: colors.black,
  inputBorderColor: colors.veryLightGray,
  inputPlaceholder: colors.veryLightGray,
  inputError: colors.peachyPink,
  buttonColor: colors.darkSlateBlue,
  buttonBackground: colors.white,
  otpDigitsInputBackground: colors.lightGray,
  screenBackground: colors.lightDarkGray,
  drawerBackground: colors.white,
  drawerBorderColor: colors.veryLightGray2,
  drawerTextColor: colors.black,
  drawerVersion: colors.darkGrey,
  tabBarBackground: colors.LighterGray,
  tabBarBackgroundActive: colors.lightGray2,
  tabBarIconDefaultColor: colors.darkSlateBlue,
  headerIcon: colors.white,
  iconColor: colors.iconBlue,

  // Content Item
  contentItemBackground: colors.veryLightBlue,
  contentItemLightBackground: colors.white,
  contentItemThumbnailBackground: colors.white,
  contentItemTitle: colors.darkSlateBlue,
  contentItemSubtitle: colors.opacityDarkSlateBlue,
  imagePlaceholder: colors.veryLightGray3,

  // Lab value
  valuesPipe: colors.darkSlateBlue,

  modalBackground: colors.white,
  contentExpiryDateGood: colors.lightSeaGreen,
  contentExpired: colors.peachyPink,
  backButton: colors.black,
  onboardingBackground: colors.darkSlateBlue,
  onboardingController: colors.white,
  onboardingHeader: colors.greenishCyanTwo,
  onboardingContent: colors.white,
  searchInput: colors.lightGray2,
  filterByTypeText: colors.darkSlateBlue,
  filterByTypeTextActive: colors.darkSlateBlue,
  searchBackButton: colors.darkSlateBlue,
  knowledgeItemSubtitle: colors.brownGrey,
  knowledgeItemIconWrapper: colors.white,
  knowledgeItemLightIconWrapper: colors.lightGray2,

  // Notification Prompt
  notificationPromptBackground: colors.darkSlateBlue,
  notificationPromptTextColor: colors.white,
  notificationPromptConfirmButtonText: colors.white,

  // Switch organization Prompt
  switchOrganizationPromptBackground: colors.lightDarkGray,
  switchOrganizationPromptTextColor: colors.darkSlateBlue,

  // Updated Indication
  updatedIndicationColor: colors.greenishCyan,

  // Search
  searchEmptyScreenHeader: colors.darkSlateBlue,

  textareaBorder: colors.darkSlateBlue,

  // Dashboard
  newsFeedBorderColor: colors.lightGray2,

  // Tooltip
  tooltipColor: colors.darkSlateBlue,

  // Folder
  folderHeaderBackground: colors.white,
  folderBackground: colors.veryLightBlue,
  folderTextColor: colors.darkSlateBlue,
  folderTextTagBackground: colors.veryLightBlue2,
  folderTitleFontSize: '14px',
  folderTitleFontWeight: '700',

  folderKnowledgeColor: '#1493eb',
  folderLabColor: '#3caeff',
  folderChecklistColor: '#31f4cf',

  contentBackgroundColor: '#2B5889',

  // Global
  headerFontSize: '26px',
  modalHeaderFontSize: '20px',
  smallFontSize: '12px',
  headerFontWeight: '700',
  subHeaderFontSize: '19px',
  subHeaderFontWeight: '700',
  itemTitleFontSize: '14px',
  itemTitleFontWeight: '500',
  labelFontSize: 14,
  valueFontSize: 16,
  labelFontWeight: '300',
  valueFontWeight: '400',
  itemsSideBarBackground: colors.white,

  headerIconSize: 30,
  itemIconSize: 17,
};

export const colorPalette = {
  // Primary colors
  primary: '#476ffd',
  primarySnowy: '#eef3ff',
  primaryLight: '#e0e9ff',
  primarySoft: '#bdd4ff',
  primarySecondary: '#85a7ff',
  primaryDark: '#0c1934',

  primaryMain1: '#EEF3FF',
  primaryMain2: '#E0E9FF',
  primaryMain4: '#85A7FF',
  primaryMain5: '#5D8BFF',
  primaryMain6: '#3662FC',
  primaryMain7: '#2A48B0',
  primaryMain8: '#1A2D71',
  primaryMain9: '#0D142D',
  primaryMain10: '#BAC8F0',

  // Text colors
  primaryTextNegative: '#eef3ff',
  primaryTextPositive: '#0c1934',

  primaryLinkCta: '#f37321',

  // General colors
  grayBorder: '#00000026',
  grayMediumLight: '#e1e1e1',
  grayLight: '#f0f0f0',
  grayDark: '#5d5d5d',

  // Natural colors
  neutral1: '#ffffff',
  neutral1p75: '#ffffff75',
  neutral2: '#F7F9FC',
  neutral3: '#E6EBF1',
  neutral4: '#D8E0EA',
  neutral5: '#A0A7BD',
  neutral6: '#4C5675',
  neutral600: '#637BAD',
  neutral6Op10: '#4C56751a',
  neutral7: '#3D4B67',
  neutral700: '#384d79',

  // Red colors
  redMedium: '#d6473e',

  // Blue colors
  bluePrimary: '#1D88FE',
  blueMedium: '#0a7aff',
  blueDark: '#243b54',

  // Green colors
  greenPrimary: '#05C168',

  // Unsafe colors
  unsafeGray: '#F5F5F5',
  unsafeOldPrimary: '#2C466D',

  // General
  white: '#ffffff',
  white20: '#ffffff33',
  red: '#DF3E34',
  black: '#000000',
  orange: '#ff8a00',
};

// new theme constants shared between web and mobile
export const antdTheme = {
  fontFamily: 'Inter, sans-serif',
  colorPrimary: colorPalette.primaryMain6,
  colorSecondary: colors.darkSlateBlue,
  colorError: colors.peachyPink,
  colorTextPlaceholder: colors.veryLightGray,
  colorBorder: colorPalette.neutral4,
  colorText: colors.grayDark,
};

/**
 * @typedef {'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl'} SizeType
 * Represents the allowed size values.
 */

/**
 * type for size
 * @type {SizeType}
 */
export const SIZE = {
  xxs: 'xxs',
  xs: 'xs',
  s: 's',
  m: 'm',
  l: 'l',
  xl: 'xl',
  xxl: 'xxl',
};

export const BUTTON = {
  OUTLINE: 'outline',
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};
