import { useEffect, useState } from 'react';

/**
 * Hook to manage the open state of a content section (knowledge, admission, lab) section
 * @param {object} props
 * @param {boolean} props.isGridView - Whether the view is grid or list
 * @param {boolean} props.isContainsActiveBinder - Whether the section contains the active binder
 * @param {boolean} props.isFirst - Whether the section is the first one
 * @param {number} props.sectionsLength - The total number of sections
 * @param {string | undefined} props.id - The section ID, if any
 * @returns {{isOpen: boolean, onToggle: (open: boolean) => void}} The state and the toggle function
 */
export function useSectionOpenState({
  isGridView,
  isContainsActiveBinder,
  isFirst,
  sectionsLength,
  id = '',
}) {
  // in grid view, open the first section by default, or if it contains the active binder or if there is only one section
  // or if the section is linked to
  const isSectionInitiallyOpen =
    (isFirst && isGridView) || isContainsActiveBinder || sectionsLength === 1;

  const [isOpen, setIsOpen] = useState(isSectionInitiallyOpen);
  const [isUserToggled, setIsUserToggled] = useState(false);

  useEffect(() => {
    // Reset user toggle state when switching views
    setIsUserToggled(false);
    setIsOpen(isSectionInitiallyOpen); // Reapply initial logic on view switch
    // eslint-disable-next-line
  }, [isGridView]);

  useEffect(() => {
    // Update only if the user hasn't manually toggled the section
    if (!isUserToggled) {
      setIsOpen(isSectionInitiallyOpen);
    }
  }, [isSectionInitiallyOpen, isUserToggled]);

  useEffect(() => {
    // If the section is open because of an active ID, treat it as user-toggled
    if (isContainsActiveBinder && !isUserToggled) {
      setIsUserToggled(true);
    }
  }, [isContainsActiveBinder, isUserToggled]);

  useEffect(() => {
    if (id && window.location.hash === `#${id}`) {
      setTimeout(() => {
        setIsOpen(true);
      }, 150); // waiting for the collapse animation to finish
    }
    // eslint-disable-next-line
  }, [id, window.location.hash]);

  const onToggle = open => {
    setIsOpen(open);
    setIsUserToggled(true); // Indicate that the user has manually toggled the section
  };

  return { isOpen, onToggle };
}
