import { Link } from 'react-router-dom-v5-compat';
import { Tooltip } from '../../../components/core';
import styles from '../knowledge-content.module.scss';
import { useContributors } from 'shared/hooks/knowledge-content.hooks';
import { ROUTES } from 'shared/constants';

export function Contributors({
  contributors,
  subtitleSeparator = '',
  titleClassName = '',
  onNavigate = () => {},
}) {
  const { allContributors, lang } = useContributors({ contributors });

  if (!allContributors.length) {
    return null;
  }

  const renderContributor = ({ displayName, email, userId }, index) => {
    const separator = index === allContributors.length - 1 ? '' : ', ';
    if (!email) {
      return (
        <Tooltip
          key={index}
          placement="rightTop"
          title={
            <p className={styles.contributorTooltip}>
              {lang.EXTERNAL_TOOLTIP_CONTRIBUTOR.replace('{name}', displayName)}
            </p>
          }
        >
          <p>{`${displayName}${separator}`}</p>
        </Tooltip>
      );
    }
    return (
      <p key={index}>
        <Link to={ROUTES.CONTACTS_PREVIEW.replace(':id', userId)} onClick={onNavigate}>
          {displayName}
        </Link>
        {separator}
      </p>
    );
  };

  return (
    <div className={styles.contributors}>
      <p className={titleClassName}>{`${subtitleSeparator}${lang.CONTRIBUTORS}: `}</p>
      {allContributors.map(renderContributor)}
    </div>
  );
}
