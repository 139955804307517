import { connect } from 'react-redux';
import { OwnerSelectorComponent } from './owner-selector.component';
import { isAccountAdmin } from 'shared/store/selectors/auth.selector';

const mapStateToProps = ({ auth, lang }) => ({
  lang: lang.OWNER_SELECTOR,
  canAddOwner: isAccountAdmin({ auth }),
});

export const OwnerSelector = connect(mapStateToProps, {})(OwnerSelectorComponent);
