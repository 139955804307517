import API from './API';
import { GET, POST } from '../utils/Executer';
import { OWNERS_SERVICE_URL as servicePath } from '../constants/APIconstants';

class OwnersServiceAPI {
  async getAll() {
    return await API.client.execute(GET, servicePath);
  }
  async get({ workspaceId }) {
    return await API.client.execute(GET, `${servicePath}?workspaceId=${workspaceId}`);
  }

  async createOwner({ owner }) {
    return await API.client.execute(POST, servicePath, owner);
  }

  async notifyOwner({ ownerId, items }) {
    return await API.client.execute(POST, `${servicePath}${ownerId}/notify`, { items });
  }
}

export default new OwnersServiceAPI();
