import { useFormik } from 'formik';
import React from 'react';
import { Popup } from '../../../../components/common';
import styles from './transfer-owner.module.scss';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';
import { Button } from 'src/components/design-system';
import { OwnerSelector } from 'src/components/ownerSelector';
import { OWNERS_TRANSFER_OWNERSHIP } from 'shared/store/actions';

export function TransferOwner({ onConfirm, onDismiss, user, workspaceId }) {
  const [isLoading, setLoadingState] = React.useState(false);
  const [error, setError] = React.useState(null);
  const dispatch = useDispatch();

  const lang = useSelector(getLang('TRANSFER_OWNERSHIP'));

  const onSubmit = async ({ ownerId }) => {
    setLoadingState(true);
    setError(null);

    // Call the delete user action
    await onConfirm({ altOwnerId: ownerId, userId: user.id });

    dispatch({ type: OWNERS_TRANSFER_OWNERSHIP });

    onDismiss();

    setLoadingState(false);
  };

  // Schema
  const TRANSFER_OWNERSHIP_SCHEMA = Yup.object().shape({
    ownerId: Yup.string().required('Required'),
  });

  const { handleSubmit, setFieldValue, errors, isValid } = useFormik({
    validationSchema: TRANSFER_OWNERSHIP_SCHEMA,
    initialValues: {
      ownerId: null,
    },
    onSubmit,
  });

  const message = lang.CONTENT.replace('{name}', `${user?.firstName} ${user?.lastName}`);

  return (
    <Popup title={lang.HEADER} isOpen={true}>
      <form onSubmit={handleSubmit}>
        <p className={styles.content}>{message}</p>

        <OwnerSelector
          currentOwnerId={user.id}
          error={errors.ownerId}
          placeholder={lang.DELETE_USER_POPUP_OWNER_PLACEHOLDER}
          name="ownerId"
          onChange={setFieldValue}
          workspaceId={workspaceId}
        />

        {error && <span className={styles.errorMessage}>{error}</span>}
        <div className={styles.buttons}>
          <Button
            loading={isLoading}
            className={styles.button}
            disabled={!isValid}
            type="primary"
            htmlType="submit"
            size="medium"
            block
          >
            {lang.CONFIRM}
          </Button>
          <Button
            disabled={isLoading}
            className={styles.button}
            size="medium"
            htmlType="button"
            block
            onClick={onDismiss}
          >
            {lang.DISMISS}
          </Button>
        </div>
      </form>
    </Popup>
  );
}
