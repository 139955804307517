import React, { useMemo } from 'react';
import styles from './rotation-content.module.scss';
import { useRotationsContext } from '../rotations-context';
import { KnowledgeTab } from './tab-resources-list.component';
import RotationOwners from './rotation-owners.component';
import RotationName from './rotation-name.component';
import { Loader } from 'src/components/core';
import { WelcomeLetterTab } from './components/tab-welcome-letter/tab-welcome-letter';
import { SettingTab, TeamTab } from './components';
import analyticsService from 'src/helpers/analytics.service';
import { EVENTS } from 'shared/constants';
import { NotFound } from 'src/components/common';
import { Tabs } from 'src/components/design-system';

const RotationContent = () => {
  const { loadingRotation, selectedRotation } = useRotationsContext();

  if (loadingRotation) {
    return (
      <div className={styles.loading}>
        <Loader />
      </div>
    );
  }

  if (!selectedRotation) {
    return <NotFound />;
  }

  return (
    <div className={styles.rotationView}>
      <RotationName />
      <RotationOwners />
      <ContentTabs />
    </div>
  );
};

const ContentTabs = () => {
  const { lang, canEditSettings } = useRotationsContext();
  const tabs = useMemo(() => {
    const tabs = [
      {
        key: lang.RESOURCES,
        label: lang.RESOURCES,
        children: <KnowledgeTab />,
      },
      {
        key: lang.TEAM,
        label: lang.TEAM,
        children: <TeamTab />,
      },
      {
        key: lang.INFO,
        label: lang.INFO,
        children: <WelcomeLetterTab />,
      },
    ];

    if (canEditSettings) {
      tabs.push({
        key: lang.SETTINGS,
        label: lang.SETTINGS,
        children: <SettingTab />,
      });
    }

    return tabs;
  }, [lang, canEditSettings]);

  const onChangeTab = key => {
    analyticsService.track(EVENTS.ROTATIONS.TAB_SELECTED, { tab: key });
  };

  return <Tabs items={tabs} onTabClick={onChangeTab} />;
};

export { RotationContent };
