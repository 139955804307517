import { Button, Divider, TextAreaInput, TextInput } from 'src/components/design-system';
import styles from './preferences-manager.module.scss';
import { useState } from 'react';
import { SectionComponent } from 'src/components/common/section/section.component';
import { Modal, Popconfirm, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { colorPalette } from 'shared/constants';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import ReactMarkdown from 'react-markdown';
import { getLang } from 'shared/store/selectors/lang.selector';
import { useSelector } from 'react-redux';
import { useProfessionalProfile } from '../professional-profile-manager';
import { Loader } from 'src/components/core';

const PREFERENCE_SCHEMA = Yup.object().shape({
  title: Yup.string().max(100, 'Too Long!').required('Title is required'),
  content: Yup.string().max(5000, 'Too Long!').required('Content is required'),
});

export function PreferencesManager({ canEdit, user }) {
  const lang = useSelector(getLang('USER_PROFILE'));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedPreferenceId, setSelectedPreferenceId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    title: null,
    content: null,
  });

  const {
    addUserPreference,
    updateUserPreference,
    deleteUserPreference,
    preferences,
    fetching,
    contextHolder,
  } = useProfessionalProfile({ userId: user.id });

  const onSubmit = async (values, { resetForm }) => {
    if (loading) return;
    setLoading(true);
    if (isEdit) {
      await updateUserPreference({
        preferenceId: selectedPreferenceId,
        preference: values,
      });
      setSelectedPreferenceId('');
    } else {
      await addUserPreference({
        preference: values,
      });
    }

    setLoading(false);
    setIsModalOpen(false);
    resetForm();
  };

  const { values, errors, handleSubmit, setFieldValue, isValid, dirty } = useFormik({
    validationSchema: PREFERENCE_SCHEMA,
    initialValues,
    enableReinitialize: true,
    onSubmit,
  });

  const handleOpenModal = (isEdit, index) => {
    setIsModalOpen(true);
    setIsEdit(isEdit);

    if (isEdit) {
      const selectedPreference = preferences[index];
      setSelectedPreferenceId(selectedPreference.id);
      setInitialValues({
        title: selectedPreference.title,
        content: selectedPreference.content,
      }); // Set form values for editing
    } else {
      setInitialValues({ title: null, content: null }); // Reset form for new preference
    }
  };

  const renderActions = (preference, index) => {
    return (
      <div className={styles.sectionTitleActions}>
        <Tooltip title={lang.EDIT_PREFERENCE}>
          <Button
            onClick={e => {
              e.stopPropagation();
              handleOpenModal(true, index);
            }}
            type="text"
            icon={<FontAwesomeIcon icon={faPencil} />}
          />
        </Tooltip>
        <Tooltip title={lang.DELETE_PREFERENCE} placement="rightBottom">
          <Popconfirm
            title={lang.DELETE_PREFERENCE_CONFIRMATION}
            cancelText={lang.CANCEL}
            okText={lang.DELETE_PREFERENCE}
            okType="danger"
            onConfirm={async e => {
              e.stopPropagation();
              await deleteUserPreference({
                preferenceId: preference.id,
              });
            }}
            onCancel={e => e.stopPropagation()}
            okButtonProps={{ type: 'primary' }}
            cancelButtonProps={{ type: 'default' }}
          >
            <Button
              type="text"
              onClick={e => e.stopPropagation()}
              icon={<FontAwesomeIcon icon={faTrashCan} color={colorPalette.red} />}
            />
          </Popconfirm>
        </Tooltip>
      </div>
    );
  };

  const renderContent = () => {
    if (fetching) {
      return <Loader />;
    }

    if (!preferences.length) {
      return <p>{lang.PREFERENCES_SECTION_EMPTY}</p>;
    }

    return preferences.map((preference, index) => {
      return (
        <div key={preference.id}>
          <SectionComponent
            title={preference.title}
            customActions={canEdit ? renderActions(preference, index) : null}
            isOpen={false}
          >
            <ReactMarkdown className={styles.content}>{preference.content}</ReactMarkdown>
          </SectionComponent>
        </div>
      );
    });
  };

  return (
    <>
      {contextHolder}
      <div className={styles.root}>
        <div className={styles.header}>
          <h3>{lang.PREFERENCES_SECTION_TITLE}</h3>
          {canEdit && (
            <Button type="default" size="small" onClick={() => handleOpenModal(false)}>
              {lang.ADD_PREFERENCE}
            </Button>
          )}
        </div>

        {renderContent()}
      </div>

      <Modal
        title={isEdit ? lang.EDIT_PREFERENCE : lang.ADD_PREFERENCE}
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
        closeIcon={null}
        centered
      >
        <form onSubmit={handleSubmit}>
          <div className={styles.fields}>
            <TextInput
              label={lang.TITLE}
              value={values.title}
              onChange={e => setFieldValue('title', e.target.value)}
              error={errors.title}
            />

            <TextAreaInput
              label={lang.CONTENT}
              value={values.content}
              onChange={e => setFieldValue('content', e.target.value)}
              error={errors.content}
              autoSize={{ minRows: 6, maxRows: 8 }}
            />
          </div>

          <Divider />

          <div className={styles.buttons}>
            <Button type="default" disabled={loading} onClick={() => setIsModalOpen(false)}>
              {lang.CANCEL}
            </Button>
            <Button
              type="primary"
              loading={loading}
              htmlType="submit"
              disabled={!isValid || !dirty}
            >
              {lang.SAVE_CHANGES}
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
}
