export const DRUG_CLASSES = {
  INDUCTION_AGENTS: 'INDUCTION_AGENTS',
  BENZOPDIAZEPINES: 'BENZOPDIAZEPINES',
  BENZOPDIAZEPINES_ANTAGONISTS: 'BENZOPDIAZEPINES_ANTAGONISTS',
  MUSCLE_RELAXANTS: 'MUSCLE_RELAXANTS',
  RELAXANT_REVERSAL_AGENTS: 'RELAXANT_REVERSAL_AGENTS',
  OPIOIDS: 'OPIOIDS',
  OPIOIDS_ANTAGONISTS: 'OPIOIDS_ANTAGONISTS',
  VASOPRESSORS: 'VASOPRESSORS',
  HYPOTENSIVE_AGENTS: 'HYPOTENSIVE_AGENTS',
  LOCAL_ANAESTHETICS: 'LOCAL_ANAESTHETICS',
  ANTICHOLINERGIC_AGENTS: 'ANTICHOLINERGIC_AGENTS',
  ANTI_EMETICS: 'ANTI_EMETICS',
  HEPARIN: 'HEPARIN',
  PROTAMINE: 'PROTAMINE',
  MISCELLANEOUS_DRUGS: 'MISCELLANEOUS_DRUGS',
  RESUSCITATION_MEDICATIONS: 'RESUSCITATION_MEDICATIONS',
  CARDIAC_MEDICATIONS: 'CARDIAC_MEDICATIONS',
  SEDATIVES: 'SEDATIVES',
  NSAIDS: 'NSAIDS',
  ANTIPRURITICS: 'ANTIPRURITICS',
  STEROIDS: 'STEROIDS',
  BRONCHODILATORS: 'BRONCHODILATORS',
  ANESTHETICS: 'ANESTHETICS',
};
