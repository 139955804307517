import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Select } from 'src/components/design-system/forms';
import { Button } from 'src/components/design-system';
import { Divider } from 'antd';
import { useSelector } from 'react-redux';
import { formatFullName, sortByAlphabet } from 'shared/utils';
import _ from 'lodash';
import { getOwnersByIDs, getOwnersListForEditor } from 'shared/store/selectors/owners.selector';
import { getLang } from 'shared/store/selectors/lang.selector';
import { isAccountAdmin } from 'shared/store/selectors/auth.selector';
import { OwnerEditor } from 'src/components/ownerSelector/owner-editor';
import { getUser } from 'shared/store/selectors/user.selector';

export function MultipleOwnersSelector({ onChange, name, parentOwnerIds, ...props }) {
  const ownersList = useSelector(({ owners }) => getOwnersListForEditor(owners));
  // the current user will be the default owner in case of content creation
  const currentUser = useSelector(getUser);
  const currentOwners = useSelector(getOwnersByIDs(props?.value || []));
  const lang = useSelector(getLang('OWNER_SELECTOR'));
  const canAddOwner = useSelector(isAccountAdmin);
  const [modalOpen, setModalOpen] = useState(false);

  const getValidOwners = useCallback(
    owners =>
      ownersList
        .filter(owner => owners.includes(owner.id) && !owner?.inactive)
        .map(owner => owner.id),
    [ownersList],
  );

  useEffect(() => {
    // If the current user is an owner, set them as the default owner
    if (props.value.length === 0 && ownersList.find(owner => owner.id === currentUser.id)) {
      onChange(name, [currentUser.id]);

      // If the current user is not an owner, set the parent owners as the default owners
    } else if (props.value.length === 0 && parentOwnerIds) {
      const parentOwnersFromThisOrg = getValidOwners(parentOwnerIds);
      onChange(name, parentOwnersFromThisOrg.length ? parentOwnersFromThisOrg : null);

      // if owners are pre-filled validate they are valid owners for this org
    } else if (props.value.length > 0) {
      const ownersFromThisOrg = getValidOwners(props.value);
      onChange(name, ownersFromThisOrg.length ? ownersFromThisOrg : null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openCreateOwner = () => {
    setModalOpen(true);
    // TODO: analytics
  };

  const closeCreateOwner = () => {
    setModalOpen(false);
  };

  const selectedOwners = useMemo(
    () =>
      currentOwners.map(owner => ({
        value: owner.id,
        label: formatFullName(owner),
      })) || [],
    [currentOwners],
  );

  const options = useMemo(() => {
    // Create a Set of unique identifiers for selected owners
    const selectedSet = new Set(selectedOwners.map(selected => selected.id));

    return (
      ownersList
        // Filter out the selected owners from all options
        .filter(({ id = '' }) => id && !selectedSet.has(id))
        .map(owner => ({
          value: owner.id,
          label: owner.value,
          disabled: owner?.inactive,
        }))
        .sort((a, b) => sortByAlphabet(a?.label, b?.label))
    );
  }, [ownersList, selectedOwners]);

  const onChangeInternal = useCallback(
    (name, currentValues) => {
      onChange(
        name,
        currentValues.filter(value => !_.isUndefined(value)),
      );
    },
    [onChange],
  );

  const handleOnNewOwner = useCallback(
    (name, value) => {
      onChange(name, [...props.value, value]);
    },
    [onChange, props.value],
  );

  return (
    <>
      {!modalOpen && (
        <Select
          mode="multiple"
          name={name}
          options={options}
          bordered={false}
          {...props}
          onChange={onChangeInternal}
          value={selectedOwners}
          dropdownRender={menu => (
            <div>
              {menu}
              {canAddOwner && (
                <>
                  <Divider style={{ margin: '4px 0' }} />
                  <Button style={{ margin: '3px 7px 7px' }} size="small" onClick={openCreateOwner}>
                    {lang.ADD_NEW_OWNER}
                  </Button>
                </>
              )}
            </div>
          )}
        />
      )}

      <OwnerEditor
        isOpen={modalOpen}
        onFinish={closeCreateOwner}
        selectOwnerId={handleOnNewOwner}
        fieldName={name}
      />
    </>
  );
}
