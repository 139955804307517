import { useSelector } from 'react-redux';
import { AuthPage } from '../authentication/auth-page-wrapper';
import styles from './maintenance.module.scss';
import { getLang } from 'shared/store/selectors/lang.selector';
import { getMaintenanceEstimatedEnd } from 'shared/store/selectors/maintenance.selector';
import classNames from 'classnames';

const MaintenancePage = () => {
  const lang = useSelector(getLang('MAINTENANCE_SCREEN'));
  const estimatedEndTime = useSelector(getMaintenanceEstimatedEnd);

  return (
    <AuthPage>
      <div className={classNames(styles.root, 'new-design-system')}>
        <h2 className={styles.header}>{lang.HEADER}</h2>
        <p>{lang.CONTENT}</p>
        {!!estimatedEndTime && (
          <p className={styles.content}>
            {lang.ESTIMATED_END.replace("'{time}'", estimatedEndTime)}
          </p>
        )}
      </div>
    </AuthPage>
  );
};

export { MaintenancePage };
