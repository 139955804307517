import { connect } from 'react-redux';
import { AdmissionBinderComponent } from './admission-binder.component';
import { getAdmissionCategoryById } from 'shared/store/selectors/admission.selector';
import { admissionSelectChecklist } from 'src/store/actions/admission-viewer.actions';
import {
  updateChecksOrder,
  createQuickCheckListItem,
} from 'shared/store/actions/admission.actions';
import { INTERNAL_ROUTING } from 'shared/constants';
import { getQueryParam } from 'src/helpers/routing.helper';

const mapStateToProps = ({ admission, application, owners, lang }) => {
  const id = getQueryParam({ key: INTERNAL_ROUTING.QUERY_PARAMS.CHECKLIST });

  const category = id ? getAdmissionCategoryById(admission, { id }) : {};

  return {
    id,
    category,
    adminMode: application.adminMode,
    lastUpdated: admission.lastUpdated,
    langPage: lang.ADMISSION_CHECKLIST_PAGE,
    langButtons: lang.CHECKLIST_BUTTONS,
    langTooltip: lang.ACTION_BUTTON_TOOLTIPS,
  };
};

const mapDispatchToProps = {
  admissionSelectChecklist,
  updateOrder: updateChecksOrder,
  createQuickCheckListItem,
};

export const AdmissionBinder = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdmissionBinderComponent);
