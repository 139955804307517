import API from './API';
import { DELETE, GET, PATCH, POST, PUT, POST_FORM } from '../utils/Executer';
import { KNOWLEDGE_SERVICE_URL as servicePath } from '../constants/APIconstants';
import { DASHBOARD_PARAMS, KNOWLEDGE_PAGE_PARAMS } from '../constants/knowledge.constants';
import axios from 'axios';

class KnowledgeServiceAPI {
  async get() {
    return await API.client.execute(GET, `${servicePath}`);
  }

  async search({ term }) {
    return await API.client.execute(GET, `${servicePath}find?term=${term}`);
  }

  async getItemByOldId({ id }) {
    return await API.client.execute(GET, `${servicePath}old-item?id=${id}`);
  }

  async getItems() {
    return await API.client.execute(GET, `${servicePath}items`);
  }

  async getItemsByPage(params = {}) {
    const { pageSize = KNOWLEDGE_PAGE_PARAMS.pageSize, page = KNOWLEDGE_PAGE_PARAMS.page } = params;
    return await API.client.execute(
      GET,
      `${servicePath}items/page?pageSize=${pageSize}&page=${page}`,
    );
  }

  async getRecentlyCreatedItems(params = {}) {
    const {
      pageSize = DASHBOARD_PARAMS.pageSize,
      page = DASHBOARD_PARAMS.page,
      sortBy = DASHBOARD_PARAMS.recentlyCreatedSort,
      direction = DASHBOARD_PARAMS.direction,
      workspaceId,
    } = params;

    const paramsToParse = { pageSize, page, sort: sortBy, direction, workspaceId };

    const query = _getCleanQueryParams(paramsToParse);

    return await API.client.execute(GET, `${servicePath}items/paginated?${query}`);
  }

  async getRecentlyUpdatedItems(params = {}) {
    const {
      pageSize = DASHBOARD_PARAMS.pageSize,
      page = DASHBOARD_PARAMS.page,
      sortBy = DASHBOARD_PARAMS.recentlyUpdatedSort,
      direction = DASHBOARD_PARAMS.direction,
      workspaceId,
    } = params;

    const paramsToParse = { pageSize, page, sort: sortBy, direction, workspaceId };

    const query = _getCleanQueryParams(paramsToParse);

    return await API.client.execute(GET, `${servicePath}items/paginated?${query}`);
  }

  async getKnowledgeItemsOpenEventsForOrganization(params = {}) {
    const { pageSize = DASHBOARD_PARAMS.pageSize, page = DASHBOARD_PARAMS.page } = params;
    return await API.client.execute(
      GET,
      `${servicePath}items/analytics/organization?pageSize=${pageSize}&page=${page}`,
    );
  }
  async getKnowledgeItemsOpenEventsForUser(params = {}) {
    const { pageSize = DASHBOARD_PARAMS.pageSize, page = DASHBOARD_PARAMS.page } = params;
    return await API.client.execute(
      GET,
      `${servicePath}items/analytics/user?pageSize=${pageSize}&page=${page}`,
    );
  }

  async getItemContent({ id }) {
    return await API.client.execute(GET, `${servicePath}items/${id}/content`);
  }

  async getItemContentAsPDF({ id }) {
    return await API.client.execute(GET, `${servicePath}items/${id}/content/pdf`);
  }

  async getCategories() {
    return await API.client.execute(GET, `${servicePath}categories`);
  }

  async updateItemKeywords({ id, keywords }) {
    return await API.client.execute(PATCH, `${servicePath}items/${id}/keywords`, { keywords });
  }

  // Knowledge Category
  async updateCategory({ id, ...change }) {
    return await API.client.execute(POST, `${servicePath}categories/${id}`, change);
  }

  async deleteCategory({ id }) {
    return await API.client.execute(DELETE, `${servicePath}categories/${id}`);
  }

  async updateCategorySubcategories({ id, categoryId, index }) {
    return await API.client.execute(POST, `${servicePath}categories/${id}/subcategories/order`, {
      categoryId,
      index,
    });
  }

  async updateKnowledgeItemsOrder({ id, itemId, index }) {
    return await API.client.execute(POST, `${servicePath}categories/${id}/items/order`, {
      itemId,
      index,
    });
  }

  async createCategory({ data, workspaceId }) {
    return await API.client.execute(
      POST,
      `${servicePath}categories?workspace=${workspaceId}`,
      data,
    );
  }

  async moveKnowledgeItem({ id, categoryId, sourceId }) {
    return await API.client.execute(POST, `${servicePath}items/${id}/move`, {
      categoryId,
      sourceId,
    });
  }

  // Knowledge Items
  async getUploadUrl(data, options = {}) {
    return await API.assetsClient.execute(POST, `/actions/upload`, data, options);
  }

  async getOriginalFile(assetName) {
    return await API.assetsClient.execute(GET, `/assets/${assetName}/original`);
  }

  // upload file to gcp
  async uploadFileThroughServer(data) {
    return await API.assetsClient.execute(POST, `/actions/upload-storage`, data, {
      headers: {
        accept: 'application/json',
        'Accept-Language': 'en-US,en;q=0.8',
        'Content-Type': `multipart/form-data`,
      },
    });
  }

  async uploadDirectlyToGCS(url, file, onUploadProgress) {
    return await axios.put(url, file, {
      headers: {
        'Content-Type': file.type,
      },
      onUploadProgress,
    });
  }

  // finalize upload of item
  async finalizedUploadFile({ id }) {
    return await API.assetsClient.execute(POST, `/assets/${id}/finalize-upload`);
  }

  // convert asset to pdf
  async convertAssetToPdf({ assetId }) {
    return await API.assetsClient.execute(POST, `/assets/${assetId}/convert`);
  }

  // Reject duplicate upload of item
  async rejectDuplicatedItem({ id }) {
    return await API.assetsClient.execute(POST, `/assets/${id}/reject-duplicate`);
  }

  async createKnowledgeItem({ data, workspaceId }) {
    return await API.client.execute(
      POST_FORM,
      `${servicePath}items?workspace=${workspaceId}`,
      data,
      {
        headers: {
          accept: 'application/json',
          'Accept-Language': 'en-US,en;q=0.8',
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  }

  async updateKnowledgeItem({ id, data }) {
    return await API.client.execute(POST_FORM, `${servicePath}items/${id}`, data, {
      headers: {
        accept: 'application/json',
        'Accept-Language': 'en-US,en;q=0.8',
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  async deleteKnowledgeItem({ id }) {
    return await API.client.execute(DELETE, `${servicePath}items/${id}`);
  }

  async createKnowledgeLink({ categoryId, data }) {
    return await API.client.execute(
      POST,
      `${servicePath}categories/${categoryId}/items/link`,
      data,
    );
  }

  async deleteKnowledgeLink({ categoryId, itemId }) {
    return await API.client.execute(
      DELETE,
      `${servicePath}categories/${categoryId}/items/link/${itemId}`,
    );
  }

  async updateKnowledgeLink({ categoryId, itemId, data }) {
    return await API.client.execute(
      PUT,
      `${servicePath}categories/${categoryId}/items/link/${itemId}`,
      data,
    );
  }

  async moveKnowledgeLink({ categoryId, newCategoryId, itemId, ownerIds }) {
    return await API.client.execute(
      PUT,
      `${servicePath}categories/${categoryId}/items/link/${itemId}/move`,
      { newCategoryId, ownerIds },
    );
  }

  // OCR conversion
  async triggerOcrConversion({ assetId }) {
    return await API.client.execute(POST, `${servicePath}assets/${assetId}`);
  }

  async fetchOcrData({ assetId }) {
    return await API.client.execute(GET, `${servicePath}assets/${assetId}`);
  }

  async getKnowledgeItemAsset({ itemId }) {
    return await API.client.execute(GET, `${servicePath}items/${itemId}/asset`);
  }
}

function _getCleanQueryParams(paramsToParse = {}) {
  // remove undefined values
  Object.keys(paramsToParse).forEach(
    key => paramsToParse[key] === undefined && delete paramsToParse[key],
  );

  const urlParams = new URLSearchParams(paramsToParse);

  return urlParams.toString();
}

export default new KnowledgeServiceAPI();
