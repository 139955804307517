import { connect } from 'react-redux';
import { AdmissionSectionComponent } from './admission-section.component';
import { getAdmissionCategoriesById } from 'shared/store/selectors/admission.selector';
import {
  updateCategory,
  updateCategorySubcategories,
} from 'shared/store/actions/admission.actions';
import { admissionSelectChecklist } from 'src/store/actions/admission-viewer.actions';
import { INTERNAL_ROUTING } from 'shared/constants';
import { getQueryParam } from 'src/helpers/routing.helper';

const mapStateToProps = ({ admission, application, lang }, { section }) => {
  const activeId = getQueryParam({ key: INTERNAL_ROUTING.QUERY_PARAMS.CHECKLIST });

  return {
    children: getAdmissionCategoriesById(admission, section.id),
    activeId,
    adminMode: application.adminMode,
    lang: lang.ACTION_BUTTON_TOOLTIPS,
  };
};

const mapDispatchToProps = {
  admissionSelectChecklist,
  updateCategory,
  updateCategorySubcategories,
};

export const AdmissionSection = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdmissionSectionComponent);
