import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { KNOWLEDGE_RESOURCES_TYPES } from 'shared/constants';
import { CategoryRowComponent } from 'src/components/common/categories/category-row';
import { Button } from 'src/components/design-system';
import { faSpinner, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import styles from './resource-manager.module.scss';
import { Popconfirm, Tooltip } from 'antd';
import { getLang } from 'shared/store/selectors/lang.selector';
import { useSelector } from 'react-redux';

const ResourceItemComponent = ({
  parsedResource = {},
  canEdit = false,
  loading = false,
  handleRemove = () => {},
  onOpenExternalUrl = () => {},
  onClick = () => {},
}) => {
  const lang = useSelector(getLang('CONTENT_SELECTOR'));
  const isExternalUrl = parsedResource?.type === KNOWLEDGE_RESOURCES_TYPES.EXTERNAL_URL;

  const actions = canEdit ? (
    <Tooltip title={lang.DELETE_RESOURCE} placement="right">
      <Popconfirm
        title={lang.DELETE_RESOURCE_CONFIRMATION}
        cancelText={lang.CANCEL_ACTION}
        okText={lang.DELETE_RESOURCE}
        okType="danger"
        onConfirm={async e => await handleRemove(e, parsedResource.id)}
        onCancel={e => e.stopPropagation()}
        okButtonProps={{ type: 'primary' }}
        cancelButtonProps={{ type: 'default' }}
      >
        <Button
          type="text"
          onClick={e => e.stopPropagation()}
          icon={
            <FontAwesomeIcon
              icon={loading === parsedResource.id ? faSpinner : faTrashAlt}
              spin={loading === parsedResource.id}
              className={styles.removeIcon}
            />
          }
        />
      </Popconfirm>
    </Tooltip>
  ) : null;

  if (isExternalUrl) {
    return (
      <a
        href={parsedResource.navigateTo}
        target="_blank"
        rel="noopener noreferrer"
        className={styles.externalUrl}
        onClick={() => onOpenExternalUrl(parsedResource.navigateTo, parsedResource)}
      >
        <CategoryRowComponent {...parsedResource} actions={actions} />
      </a>
    );
  }

  return (
    <CategoryRowComponent
      {...parsedResource}
      actions={actions}
      onClick={() => onClick(parsedResource.navigateTo, parsedResource)}
    />
  );
};

export { ResourceItemComponent };
