import React, { useCallback } from 'react';
import rotationsServiceApi from 'shared/api/rotations.service.api';
import styles from './tab-welcome-letter.module.scss';
import { notification } from 'antd';
import { Button } from 'src/components/design-system';
import * as Yup from 'yup';
import { useRotationsContext } from '../../../rotations-context';
import { sanitizeHtmlContent } from 'shared/utils';
import { Select, TextAreaInput, TextInput } from 'src/components/design-system/forms';
import { NotFound } from 'src/components/common';
import { useFormik } from 'formik';
import analyticsService from 'src/helpers/analytics.service';
import { EVENTS } from 'shared/constants';

const INFO_SCHEMA = Yup.object().shape({
  subject: Yup.string().required('Required!').min(3, 'Too Short!').max(100, 'Too Long!'),
  body: Yup.string().required('Required!').min(3, 'Too Short!').max(4000, 'Too Long!'),
  deliverDaysBefore: Yup.mixed()
    .test(
      'is-valid-deliver-days',
      'Value must be between 0 and 15, or equal to null',
      value => (typeof value === 'number' && value >= 0 && value <= 15) || value === null,
    )
    .nullable(),
});

export function WelcomeLetterTab() {
  const { lang, selectedRotation: rotation, canEdit, updateRotationData } = useRotationsContext();

  const [editMode, setEditMode] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [loadingPreview, setLoadingPreview] = React.useState(false);

  const [api, contextHolder] = notification.useNotification();

  React.useEffect(() => {
    setEditMode(false);
  }, [rotation]);

  const { subject, body, deliverDaysBefore } = rotation.welcomeLetter || {};

  const onSubmit = async ({ subject, body, deliverDaysBefore }) => {
    setLoading(true);

    body = sanitizeHtmlContent(body);

    try {
      const res = await rotationsServiceApi.updateWelcomeLetter({
        rotationId: rotation.id,
        payload: {
          subject,
          body,
          deliverDaysBefore,
        },
      });

      if (res.error) {
        throw new Error(res.error);
      }

      updateRotationData({
        welcomeLetter: res.data,
      });

      analyticsService.track(EVENTS.ROTATIONS.WELCOME_LETTER_SAVE, {
        rotationId: rotation.id,
        rotationName: rotation.name,
      });

      api.success({
        message: lang.INFO_UPDATE_SUCCESS,
        duration: 5,
      });
    } catch (e) {
      api.error({
        message: e.message,
        duration: 5,
      });
    }

    setLoading(false);
    setEditMode(false);
  };

  // Formik
  const { handleSubmit, handleChange, setFieldValue, values, errors, touched, isValid, dirty } =
    useFormik({
      validationSchema: INFO_SCHEMA,
      initialValues: {
        subject: subject || lang.INFO_DEFAULT_SUBJECT.replace('{rotationName}', rotation.name),
        body: body || lang.INFO_DEFAULT_CONTENT.replace('{rotationName}', rotation.name),
        deliverDaysBefore: deliverDaysBefore || null,
      },
      onSubmit,
    });

  const sendPreview = useCallback(async () => {
    setLoadingPreview(true);

    try {
      const res = await rotationsServiceApi.sendPreviewWelcomeLetter({
        rotationId: rotation.id,
        payload: {
          subject: values.subject,
          body: sanitizeHtmlContent(values.body),
        },
      });

      analyticsService.track(EVENTS.ROTATIONS.WELCOME_LETTER_PREVIEW, {
        rotationId: rotation.id,
        rotationName: rotation.name,
      });

      if (res.error) {
        throw new Error(res.error);
      }

      api.success({
        message: lang.INFO_SEND_PREVIEW_SUCCESS,
        duration: 5,
      });
    } catch (e) {
      api.error({ message: e.message, duration: 5 });
    }

    setLoadingPreview(false);
  }, [api, lang.INFO_SEND_PREVIEW_SUCCESS, rotation, values.body, values.subject]);

  const deliverDaysBeforeOption = React.useMemo(() => {
    // generate options for the select from 1 to 14 and use lang
    const normalValues = Array.from({ length: 14 }, (_, i) => i + 1).map(i => ({
      label: lang[`INFO_DAYS_BEFORE_${i > 1 ? 'PLURAL' : 'SINGULAR'}`].replace('{number}', i),
      value: i,
    }));
    const options = [
      {
        label: lang.INFO_DO_NOT_SEND,
        value: null,
      },
      ...normalValues,
    ];

    return options;
  }, [lang]);

  const editWelcomeLetter = () => {
    analyticsService.track(EVENTS.ROTATIONS.WELCOME_LETTER_EDIT, {
      rotationId: rotation.id,
      rotationName: rotation.name,
    });

    setEditMode(true);
  };

  const getDaysBeforeLang = () => {
    if (deliverDaysBefore === null) {
      return lang.INFO_DO_NOT_SEND;
    }

    const deliverDaysBeforeLang =
      deliverDaysBefore > 1 ? lang.INFO_DAYS_BEFORE_PLURAL : lang.INFO_DAYS_BEFORE_SINGULAR;

    return deliverDaysBeforeLang.replace('{number}', deliverDaysBefore);
  };

  const renderEditableContent = () => {
    // Admin in preview mode
    if (!editMode) {
      return (
        <>
          <div className={styles.buttons}>
            <Button onClick={editWelcomeLetter}>{lang.INFO_EDIT}</Button>
          </div>

          {body && (
            <div className={styles.main}>
              <div>
                <label>{lang.INFO_DELIVER_DAYS_BEFORE}</label>
                <p>{getDaysBeforeLang()}</p>
              </div>

              <div>
                <label>{lang.INFO_SUBJECT}</label>
                <p>{subject}</p>
              </div>

              <div>
                <label>{lang.INFO_CONTENT}</label>
                <div
                  id="content-container"
                  className={styles.preview}
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtmlContent(body),
                  }}
                />
              </div>
            </div>
          )}
          {!body && <NotFound text={lang.INFO_NO_CONTENT} />}
        </>
      );
    }

    return (
      <form onSubmit={handleSubmit}>
        <div className={styles.buttons}>
          <Button onClick={() => setEditMode(false)} htmlType="button">
            {lang.CANCEL}
          </Button>
          <Button
            onClick={sendPreview}
            htmlType="button"
            loading={loadingPreview}
            disabled={!isValid || loading}
          >
            {lang.INFO_SEND_PREVIEW}
          </Button>
          <Button
            type="primary"
            disabled={!isValid || loadingPreview || !dirty}
            htmlType="submit"
            loading={loading}
          >
            {lang.SUBMIT}
          </Button>
        </div>

        <div className={styles.main}>
          <div>
            <label>{lang.INFO_DELIVER_DAYS_BEFORE}</label>
            <Select
              value={values.deliverDaysBefore}
              error={errors.deliverDaysBefore}
              touched={touched.deliverDaysBefore}
              onChange={setFieldValue}
              name="deliverDaysBefore"
              options={deliverDaysBeforeOption}
              placeholder={lang.INFO_DELIVER_DAYS_BEFORE_PLACEHOLDER}
            />
          </div>

          <div>
            <label>{lang.INFO_SUBJECT}</label>
            <TextInput
              error={errors.subject}
              touched={touched.subject}
              onChange={handleChange}
              name="subject"
              placeholder={lang.INFO_SUBJECT_PLACEHOLDER}
              value={values.subject}
              disabled={values.deliverDaysBefore === null}
            />
          </div>

          <div>
            <label>{lang.INFO_CONTENT}</label>
            <TextAreaInput
              onChange={handleChange}
              name="body"
              error={errors.body}
              value={values.body}
              rows={10}
              placeholder={lang.INFO_CONTENT_PLACEHOLDER}
              disabled={values.deliverDaysBefore === null}
            />
          </div>
        </div>
      </form>
    );
  };

  const renderContent = () => {
    if (canEdit) {
      return renderEditableContent();
    }

    if (!body) {
      return <NotFound text={lang.INFO_NO_CONTENT} />;
    }

    return (
      <div
        id="content-container"
        className={styles.preview}
        dangerouslySetInnerHTML={{
          __html: sanitizeHtmlContent(body),
        }}
      />
    );
  };

  return (
    <div className={styles.content}>
      {contextHolder}
      {renderContent()}
    </div>
  );
}
