import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';
import styles from './shared-styles.module.scss';
import { Select } from 'src/components/design-system';

export function SmartFormatToggle({ smartFormatMode, toggleSmartFormat }) {
  const lang = useSelector(getLang('KNOWLEDGE_EDITOR'));

  return (
    <div className={styles.smartFormatToggle}>
      <Select
        value={smartFormatMode}
        label={lang.VIEW_AS}
        options={[
          { value: true, label: lang.SMART_FORMAT },
          { value: false, label: lang.PDF },
        ]}
        onChange={(n, v) => toggleSmartFormat(v)}
      />
    </div>
  );
}
