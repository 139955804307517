import React from 'react';
import classnames from 'classnames';
import styles from '../knowledge.module.scss';
import { KnowledgeCategory } from '../knowledge-category/knowledge-category.component';
import update from 'immutability-helper';
import { useDrop } from 'react-dnd';
import { Lang } from 'shared/constants';
import { CreateBox } from 'src/components/admin';
import { SectionComponent } from 'src/components/common/section/section.component';
import { useSectionOpenState, useViewSelector } from 'src/components/common/view-selector';
import { useSelector } from 'react-redux';
import { haveEditPermissions } from 'shared/store/selectors/knowledge.selector';

const KnowledgeSectionComponent = React.memo(function KnowledgeSectionComponent({
  id,
  title,
  subtitle,
  children,
  adminMode,
  activeId,
  knowledgeSelectItem,
  deleteCategory,
  editCategory,
  createCategory,
  fullPath,
  path,
  type,
  updateCategory,
  updateCategorySubcategories,
  lastItem,
  firstItem,
  changeSectionOrder,
  ownerIds,
  index,
  tags,
  options,
  lang,
  labels,
  sectionsLength = 0,
}) {
  // drag and drop items
  const dndGroup = `contentItems-${id}`;
  const [, drop] = useDrop({ accept: dndGroup });
  const [items, setItems] = React.useState(children);
  let isLoading = false;

  const hasEditPermission = useSelector(state => haveEditPermissions(state, { ownerIds }));

  React.useEffect(() => {
    setItems(children);
  }, [children]);

  const dragItem = (id, atIndex) => {
    const { item, index } = findItem(id);
    setItems(
      update(items, {
        $splice: [
          [index, 1],
          [atIndex, 0, item],
        ],
      }),
    );
  };

  const findItem = id => {
    const item = items.find(i => `${i.id}` === id);
    return {
      item,
      index: items.indexOf(item),
    };
  };

  const finishDrag = categoryId => {
    updateCategorySubcategories({
      id,
      categoryId,
      index: findItem(categoryId).index,
    });
  };

  // Modification actions
  const deleteCategoryAction = React.useCallback(
    e => {
      e.stopPropagation();
      // TODO: Analytics

      // Check if user has edit permission
      if (!hasEditPermission) {
        return false;
      }

      deleteCategory({ id, title });
    },
    [deleteCategory, id, title, hasEditPermission],
  );

  // Modification actions
  const editCategoryAction = React.useCallback(
    e => {
      e.stopPropagation();
      // TODO: Analytics

      // Check if user has edit permission
      if (!hasEditPermission) {
        return false;
      }

      editCategory({ id, title, subtitle, ownerIds, path, type, tags, options, labels });
    },
    [
      hasEditPermission,
      editCategory,
      id,
      title,
      subtitle,
      ownerIds,
      path,
      type,
      tags,
      options,
      labels,
    ],
  );

  const createNewCategoryAction = React.useCallback(() => {
    // TODO: Analytics
    createCategory({ path: fullPath, ownerIds, tags, labels });
  }, [createCategory, fullPath, ownerIds, tags, labels]);

  const changeOrder = React.useCallback(
    async e => {
      e.stopPropagation();
      const { target } = e;

      if (isLoading) return false;
      // eslint-disable-next-line react-hooks/exhaustive-deps
      isLoading = true;

      const { dataset } = target;

      const toIndex = dataset.direction === 'up' ? index - 1 : index + 1;
      await changeSectionOrder(id, toIndex);
      isLoading = false;
    },
    [index, id],
  );

  const { isGridView } = useViewSelector();

  const renderItems = () => {
    if (!items) return null;

    return (
      <div ref={drop} className={isGridView ? styles.items : styles.itemsColumn}>
        {items.map(item => (
          <KnowledgeCategory
            key={`content-box-${item.id}`}
            {...item}
            {...{
              adminMode,
              activeId,
              knowledgeSelectItem,
              deleteCategory,
              updateCategory,
              editCategory,
              dragItem,
              findItem,
              dndGroup,
              finishDrag,
              lang,
            }}
          />
        ))}
        <CreateBox adminMode={adminMode} onClick={createNewCategoryAction} lang={lang} />
      </div>
    );
  };

  const adminActions = [
    {
      label: lang.MOVE_UP,
      onClick: changeOrder,
      props: { 'data-direction': 'up', hidden: firstItem },
    },
    {
      label: lang.MOVE_DOWN,
      onClick: changeOrder,
      props: { 'data-direction': 'down', hidden: lastItem },
    },
    {
      label: lang.EDIT,
      onClick: editCategoryAction,
      tooltip: !hasEditPermission && lang.NOT_ALLOWED_TO_EDIT,
      disabled: !hasEditPermission,
    },
    {
      label: lang.DELETE,
      onClick: deleteCategoryAction,
      tooltip: !hasEditPermission && lang.NOT_ALLOWED_TO_EDIT,
      disabled: !hasEditPermission,
    },
  ];

  const onBlur = React.useCallback(
    e => {
      e.stopPropagation();
      const { textContent: value } = e.target;
      // TODO: add analytics

      if (value === title) return false;

      updateCategory({ id, title: value, tags });
    },
    [id, tags, title, updateCategory],
  );

  const { isOpen, onToggle } = useSectionOpenState({
    id,
    isGridView,
    isFirst: firstItem,
    isContainsActiveBinder: items.some(item => item.id === activeId),
    sectionsLength,
  });

  return (
    <div key={id} className={classnames(styles.section, adminMode ? styles.admin : {})}>
      <span className={styles.anchor} data-type="anchor" id={id} />
      <SectionComponent
        title={title}
        showActions={adminMode}
        onBlur={onBlur}
        adminActions={adminActions}
        isOpen={isOpen}
        onToggle={onToggle}
        defaultOpenBehavior={false}
      >
        {renderItems()}
      </SectionComponent>
    </div>
  );
});

KnowledgeSectionComponent.defaultProps = {
  itemsLastUpdatedAt: null,
  knowledgeItems: [],
  children: [],
  adminMode: false,
  activeId: null,
  fullPath: null,
  knowledgeSelectItem: () => {},
  createCategory: () => {},
  deleteCategory: () => {},
  editCategory: () => {},
  updateCategory: () => {},
  updateCategorySubcategories: () => {},
  changeSectionOrder: () => {},
  index: null,
  ownerIds: [],
  lastItem: false,
  firstItem: false,
  lang: Lang.ACTION_BUTTON_TOOLTIPS,
};

export { KnowledgeSectionComponent };
