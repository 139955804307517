import React from 'react';
import { useHistory } from 'react-router';
import { useRotationsContext } from '../../../rotations-context';
import { SectionComponent } from 'src/components/common/section/section.component';
import { CONTACT_TYPES, EVENTS, ROUTES, TEAM_GROUPS } from 'shared/constants';
import { formatFullName } from 'shared/utils';
import { TeamManager } from 'src/components/team/team-manager.component';
import analyticsService from 'src/helpers/analytics.service';

const TeamTabComponent = ({ contacts, getContactById }) => {
  const history = useHistory();
  const { selectedRotation, addTeamMembers, lang, canEdit, removeTeamMember } =
    useRotationsContext();

  const mapContactsToOptions = contact => ({
    label: formatFullName(contact),
    value: contact.id,
  });

  const filterExistingMembers = (id, group) => !group?.some(member => member.id === id);

  const staffOptions = contacts
    .filter(({ isUser = false }) => isUser)
    .filter(({ id }) => filterExistingMembers(id, selectedRotation?.team?.staff))
    .map(mapContactsToOptions);

  const contactOptions = contacts
    .filter(({ id }) => filterExistingMembers(id, selectedRotation?.team?.contacts))
    .map(mapContactsToOptions);

  const onTeamMemberClicked = (group, id) => {
    analyticsService.track(EVENTS.ROTATIONS.TEAM_MEMBER_CLICKED, {
      rotationId: selectedRotation.id,
      rotationName: selectedRotation.name,
      group,
      memberId: id,
    });

    const url = ROUTES.CONTACTS_PREVIEW.replace(':id', id);
    history.push(url);
  };

  const renderTeamManager = ({ group, availableOptions, notFoundText, canEdit }) => (
    <TeamManager
      existingUsers={selectedRotation.team?.[group]?.map(({ id }) => getContactById(id)) || []}
      availableUsers={availableOptions}
      onAddUsers={users =>
        addTeamMembers(
          users.map(id => ({
            id,
            type: getContactById(id)?.isUser ? CONTACT_TYPES.USER : CONTACT_TYPES.CONTACT,
          })),
          group,
        )
      }
      onRemoveUser={id => removeTeamMember(id, group)}
      onClickRow={id => onTeamMemberClicked(group, id)}
      canEdit={canEdit}
      notFoundText={notFoundText}
    />
  );

  return (
    <div>
      <SectionComponent
        title={lang.CORE_STAFF.replace('{size}', selectedRotation?.team?.staff?.length || 0)}
        isOpen={selectedRotation?.team?.staff?.length > 0}
        showActions={false}
      >
        {renderTeamManager({
          group: TEAM_GROUPS.STAFF,
          availableOptions: staffOptions,
          notFoundText: lang.NOT_FOUND_STAFF,
          canEdit,
        })}
      </SectionComponent>

      <SectionComponent
        title={lang.IMPORTANT_CONTACTS.replace(
          '{size}',
          selectedRotation?.team?.contacts?.length || 0,
        )}
        isOpen={selectedRotation?.team?.contacts?.length > 0}
        showActions={false}
      >
        {renderTeamManager({
          group: TEAM_GROUPS.CONTACTS,
          availableOptions: contactOptions,
          notFoundText: lang.NOT_FOUND_CONTACTS,
          canEdit,
        })}
      </SectionComponent>

      <SectionComponent
        title={lang.TRAINEES.replace('{size}', selectedRotation?.team?.trainees?.length || 0)}
        isOpen={selectedRotation?.team?.trainees?.length > 0}
        showActions={false}
      >
        {renderTeamManager({
          group: TEAM_GROUPS.TRAINEES,
          availableOptions: [],
          notFoundText: lang.NOT_FOUND_TRAINEES,
          canEdit: false,
        })}
      </SectionComponent>
    </div>
  );
};

export { TeamTabComponent };
