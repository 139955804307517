import { Divider } from 'src/components/design-system';
import { PreferencesManager } from '../preferences-manager';
import ProfileResourceManager from '../profile-resource-manager';

export function ProfessionalProfileManager({ canEdit, user }) {
  return (
    <>
      <PreferencesManager canEdit={canEdit} user={user} />
      <Divider />
      <ProfileResourceManager canEdit={canEdit} user={user} />
    </>
  );
}
