import React from 'react';
import { ArrowBlack } from '../../../../images/ui';
import styles from './revision-log.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons/faArrowUpRightFromSquare';
import { useDateFormatter } from 'shared/hooks/dates.hooks';
import knowledgeServiceApi from 'shared/api/knowledge.service.api';

const RevisionLog = React.memo(function RevisionLog({ closeLog, log = [], lang, link }) {
  const [originalLinkObject, setOriginalLinkObject] = React.useState(null);
  const { formatLongDateTime } = useDateFormatter();

  const getOriginalFile = React.useCallback(async link => {
    if (link) {
      const res = await knowledgeServiceApi.getOriginalFile(
        link.split('/').pop().split('.').shift(),
      );

      if (res.data?.url) {
        setOriginalLinkObject(res.data);
      }
    } else {
      setOriginalLinkObject(null);
    }
  }, []);

  React.useEffect(() => {
    getOriginalFile(link);
  }, [getOriginalFile, link]);

  const renderOriginalLink = () => {
    let originalLink = { url: link, format: '' };

    if (originalLinkObject?.url) {
      originalLink = originalLinkObject;
    }

    if (!originalLink.url) {
      return null;
    }

    return (
      <a href={originalLink.url} target="_blank" className={styles.viewOriginal} rel="noreferrer">
        {lang.VIEW_ORIGINAL} {originalLink.format && `(${originalLink.format.toUpperCase()})`}
        <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
      </a>
    );
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <button className={styles.buttonWrapper}>
          <img alt="back" src={ArrowBlack} className={styles.backArrow} onClick={closeLog} />
        </button>
        <h2 className={styles.title}>{lang.TITLE}</h2>
        {renderOriginalLink()}
      </div>
      <table className={styles.logsTable}>
        <thead>
          <tr>
            <td>{lang.DATE}</td>
            <td>{lang.USER}</td>
          </tr>
        </thead>
        <tbody>
          {log.map(entry => (
            <tr key={`${entry.email}-${entry.updatedAt}`}>
              <td>{formatLongDateTime(entry.updatedAt)}</td>
              <td>{entry.email}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
});

export { RevisionLog };
