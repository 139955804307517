import styles from './workspace-editor.module.scss';
import { Button, Tabs } from 'src/components/design-system';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';
import { WorkspaceGeneralInfo } from '../workspace-general-info';
import { useSearchParams } from 'react-router-dom-v5-compat';
import { Modal, notification, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colorPalette } from 'shared/constants';
import { faCircleXmark } from '@fortawesome/pro-light-svg-icons';
import { CopyWorkspaceSettings } from '../copy-workspace-settings';
import { useWorkspaceApi } from '../../hooks';
import { CREATE_WORKSPACE_SCHEMA } from './workspace.schema';
import { useWorkspacesContext, WORKSPACE_SEARCH_PARAMS } from '../../hooks/use-workspaces-context';
import { WorkspaceRestrictions } from '../workspace-restrictions';
import { faLock } from '@fortawesome/pro-light-svg-icons';
import { isWorkspaceRestrictionsEnabled } from 'shared/store/selectors/features.selector';

export function WorkspaceEditor() {
  const lang = useSelector(getLang('SETTINGS'));
  const [isCopyWorkspaceOpen, setIsCopyWorkspaceModalOpen] = useSearchParams(
    new URLSearchParams({ copy: false }),
  );

  const isRestrictedFeatureEnabled = useSelector(isWorkspaceRestrictionsEnabled);
  const { workspace, onChangeWorkspace, isNew } = useWorkspacesContext();

  const { createWorkspace } = useWorkspaceApi();

  const [api, contextHolder] = notification.useNotification({
    placement: 'bottomRight',
  });
  const [loading, setLoading] = useState(false);

  const isCopyWorkspaceModalOpen = useMemo(
    () => isCopyWorkspaceOpen.get(WORKSPACE_SEARCH_PARAMS.COPY_SETTINGS) === 'true',
    [isCopyWorkspaceOpen],
  );

  const [newWorkspaceValidation, setNewWorkspaceValidation] = useState({
    isValid: false,
    error: null,
  });

  useEffect(() => {
    const validateWorkspace = async () => {
      const { name, operational } = workspace;

      if (!isNew) {
        setNewWorkspaceValidation({ isValid: true, error: null });
        return;
      }

      try {
        await CREATE_WORKSPACE_SCHEMA.validate({ name, operational }, { abortEarly: false });
        setNewWorkspaceValidation({ isValid: true, error: null });
      } catch (error) {
        setNewWorkspaceValidation({ isValid: false, error: error.errors.join(', ') });
      }
    };

    validateWorkspace();
  }, [workspace, isNew]);

  const publishWorkspace = async () => {
    if (!isNew) {
      return;
    }
    setLoading(true);

    const { name, operational } = workspace;

    const newWorkspace = await createWorkspace({ name, operational });
    if (newWorkspace?.error) {
      api.error({
        message: newWorkspace.error,
      });
      setLoading(false);
      return;
    }

    onChangeWorkspace(newWorkspace, { setSearchParams: true });
    setLoading(false);

    api.success({
      message: lang.WORKSPACE_PUBLISHED,
    });
  };

  const tabs = useMemo(
    () => [
      {
        key: lang.GENERAL_INFO_TAB,
        label: lang.GENERAL_INFO_TAB,
        children: <WorkspaceGeneralInfo />,
      },
      {
        key: lang.RESTRICTIONS_TAB,
        label: lang.RESTRICTIONS_TAB,
        children: <WorkspaceRestrictions />,
        hide: !isRestrictedFeatureEnabled || isNew,
      },
    ],
    [lang, isNew, isRestrictedFeatureEnabled],
  );

  if (!workspace) {
    return null;
  }

  const openCopyWorkspaceModal = () => {
    setIsCopyWorkspaceModalOpen(
      prev => {
        prev.set(WORKSPACE_SEARCH_PARAMS.COPY_SETTINGS, true);
        return prev;
      },
      {
        replace: true,
      },
    );
  };

  const closeCopyWorkspaceModal = () => {
    setIsCopyWorkspaceModalOpen(
      prev => {
        prev.delete(WORKSPACE_SEARCH_PARAMS.COPY_SETTINGS);
        return prev;
      },
      { replace: true },
    );
  };

  const cloneWorkspace = newWorkspace => {
    onChangeWorkspace(newWorkspace, { setSearchParams: true });
    closeCopyWorkspaceModal();
  };

  return (
    <>
      {contextHolder}
      <div className={styles.root}>
        <header className={styles.header}>
          <div className={styles.copyHeaderWrapper}>
            <h2 className={styles.workspaceName}>{workspace.name || lang.UNTITLED_WORKSPACE}</h2>
            {workspace?.restricted && (
              <Tooltip title={lang.RESTRICTED}>
                <FontAwesomeIcon icon={faLock} className={styles.icon} />
              </Tooltip>
            )}
            {isNew && <Button onClick={openCopyWorkspaceModal}>{lang.COPY_WORKSPACE}</Button>}
          </div>
          {isNew && (
            <Tooltip title={newWorkspaceValidation.error} color={colorPalette.red}>
              <Button
                onClick={publishWorkspace}
                type="primary"
                loading={loading}
                disabled={!newWorkspaceValidation.isValid}
              >
                {lang.PUBLISH_WORKSPACE}
              </Button>
            </Tooltip>
          )}
        </header>
        <Tabs items={tabs.filter(({ hide }) => !hide)} />
      </div>

      <Modal
        title={<h2 className={styles.modalTitle}>{lang.COPY_WORKSPACE}</h2>}
        open={isCopyWorkspaceModalOpen}
        onCancel={closeCopyWorkspaceModal}
        closeIcon={<FontAwesomeIcon color={colorPalette.blueDark} size="xl" icon={faCircleXmark} />}
        centered
        footer={null}
      >
        <CopyWorkspaceSettings
          key={workspace.id}
          workspaceToCopyTo={workspace}
          onCancel={closeCopyWorkspaceModal}
          onSubmit={cloneWorkspace}
        />
      </Modal>
    </>
  );
}
