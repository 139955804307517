import React from 'react';
import { Divider as AntDivider, ConfigProvider } from 'antd';
import styles from './divider.module.scss';
import { colorPalette } from 'shared/constants';

/**
 * @typedef {import('antd').DividerProps
 *  & {
 *     className?: string
 * }} DividerProps
 */
const Divider = React.memo(
  /**
   * @param {DividerProps} props
   * @returns
   */
  function Divider(props) {
    return (
      <ConfigProvider
        theme={{
          components: {
            Divider: {
              colorSplit: props.dividerColor ?? colorPalette.neutral5,
            },
          },
        }}
      >
        <AntDivider className={styles.divider} {...props} />
      </ConfigProvider>
    );
  },
);

export { Divider };
