import React from 'react';
import rotationsServiceApi from 'shared/api/rotations.service.api';
import styles from './tab-settings.module.scss';
import { Checkbox, Modal, notification } from 'antd';
import { Button } from 'src/components/design-system';
import * as Yup from 'yup';
import { useRotationsContext } from '../../../rotations-context';
import { Select, TextInput } from 'src/components/design-system/forms';
import { useFormik } from 'formik';
import _ from 'lodash';

const SETTINGS_SCHEMA = Yup.object().shape({
  codeAliases: Yup.array()
    .of(Yup.string().min(2, 'Too Short!').max(10, 'Too Long!'))
    .test('unique', 'Only unique values allowed.', value =>
      value ? value.length === new Set(value)?.size : true,
    ),
});

const DELETE_SCHEMA = Yup.object().shape({
  destinationRotationId: Yup.string(),
  cloneContent: Yup.bool(),
});

function DeleteFlow({ notificationApi }) {
  const {
    lang,
    selectedRotation: rotation,
    allRotations,
    setRotationId,
    fetchRotations,
  } = useRotationsContext();

  const [deleteModal, setDeleteModalState] = React.useState(false);
  const [loadingDelete, setLoadingDelete] = React.useState(false);

  const onSubmit = async ({ destinationRotationId, cloneContent }) => {
    setLoadingDelete(true);

    try {
      const res = await rotationsServiceApi.deleteRotation({
        rotationId: rotation.id,
        payload: {
          destinationRotationId,
          cloneContent,
        },
      });

      if (res.error) {
        throw new Error(res.data?.message || res.error);
      }

      resetForm();
      let nextRotationId = destinationRotationId || '';
      if (!destinationRotationId && allRotations.length > 0 && allRotations[0].id !== rotation.id) {
        nextRotationId = allRotations[0].id;
      }

      // Remove deleted rotation from the list
      setRotationId(nextRotationId);
      await fetchRotations({ showLoading: false });
    } catch (e) {
      notificationApi.error({
        message: e.message,
        duration: 5,
      });
    } finally {
      setLoadingDelete(false);
    }
  };

  // Formik
  const { handleSubmit, setFieldValue, values, resetForm } = useFormik({
    validationSchema: DELETE_SCHEMA,
    initialValues: {
      destinationRotationId: '',
      cloneContent: true,
    },
    onSubmit,
  });

  const onCheckChange = e => {
    let { checked, name } = e.target;
    setFieldValue(name, checked);
  };

  const options = React.useMemo(
    () =>
      allRotations
        .filter(({ id }) => id !== rotation.id)
        .map(({ id, name }) => ({ value: id, label: name })),
    [allRotations, rotation.id],
  );

  return (
    <>
      <label>{lang.DANGER_ZONE}</label>
      <p>{lang.DANGER_ZONE_WARNING}</p>
      <Button type="primary" danger onClick={() => setDeleteModalState(true)}>
        {lang.DELETE_ROTATION}
      </Button>

      <Modal
        title={lang.DELETE_ROTATION}
        open={deleteModal}
        onOk={handleSubmit}
        confirmLoading={loadingDelete}
        okText={lang.DELETE_ROTATION}
        centered
        okButtonProps={{
          danger: true,
        }}
        cancelButtonProps={{
          disabled: loadingDelete,
        }}
        onCancel={() => setDeleteModalState(false)}
      >
        <form onSubmit={handleSubmit}>
          <p className={styles.warning}>{lang.DELETE_ROTATION_WARNING}</p>
          <label>{lang.DELETE_ROTATION_MIGRATE_MESSAGE}</label>
          <Select
            size="large"
            allowClear
            placeholder={lang.DELETE_ROTATION_MIGRATE_PLACEHOLDER}
            name="destinationRotationId"
            onChange={(f, v) => setFieldValue(f, v)}
            options={options}
          />
          <label>
            <Checkbox
              name="cloneContent"
              defaultChecked={values.cloneContent}
              onChange={onCheckChange}
              disabled={_.isEmpty(values.destinationRotationId)}
            />{' '}
            {lang.DELETE_ROTATION_CLONE_DATA}
          </label>
        </form>
      </Modal>
    </>
  );
}

export function SettingTab() {
  const { lang, selectedRotation: rotation, updateRotationData } = useRotationsContext();

  const [loading, setLoading] = React.useState(false);

  const [notificationApi, contextHolder] = notification.useNotification({
    placement: 'bottomRight',
  });

  const onSubmit = async ({ codeAliases }) => {
    setLoading(true);

    try {
      const res = await rotationsServiceApi.updateRotation({
        rotationId: rotation.id,
        payload: {
          codeAliases,
        },
      });

      if (res.error) {
        throw new Error(res.data?.message || res.error);
      }

      updateRotationData({
        codeAliases: res.data.codeAliases,
      });

      notificationApi.success({
        message: lang.ROTATION_UPDATE_SUCCESSFULLY,
        duration: 5,
      });
    } catch (e) {
      notificationApi.error({
        message: e.message,
        duration: 5,
      });
    }

    setLoading(false);
  };

  // Formik
  const { handleSubmit, setFieldValue, values, errors, isValid, dirty } = useFormik({
    validationSchema: SETTINGS_SCHEMA,
    enableReinitialize: true,
    initialValues: {
      codeAliases: rotation.codeAliases,
    },
    onSubmit,
  });

  const updateCodeAliases = (f, v) => {
    setFieldValue(
      f,
      v.map(value => value.toUpperCase().trim()),
    );
  };

  return (
    <div className={styles.content}>
      {contextHolder}
      <div className={styles.main}>
        <div>
          <form onSubmit={handleSubmit}>
            <label>{lang.ROTATION_CODE}</label>
            <TextInput
              disabled={true}
              name="code"
              placeholder={lang.ROTATION_CODE}
              value={rotation.code}
            />

            <label className={styles.editOwnersTitle}>{lang.ROTATION_CODE_ALIASES}</label>
            <Select
              mode="tags"
              size="large"
              name="codeAliases"
              onChange={updateCodeAliases}
              options={[]}
              value={values.codeAliases}
              error={errors.codeAliases}
            />
            <div className={styles.actionButtons}>
              <Button
                type="primary"
                onClick={handleSubmit}
                loading={loading}
                disabled={!dirty || !isValid}
              >
                {lang.SUBMIT}
              </Button>
            </div>
          </form>
        </div>

        <div className={styles.section}>
          <DeleteFlow notificationApi={notificationApi} />
        </div>
      </div>
    </div>
  );
}
