import { Collapse, ConfigProvider } from 'antd';
import React, { useState } from 'react';
import styles from './section.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons';
import { ActionsDropdown } from '../actions-dropdown/actions-dropdown.component';
import { colorPalette } from 'shared/constants';

export function SectionComponent({
  title,
  adminMode,
  onBlur,
  adminActions,
  customActions = null,
  children,
  showActions = false,
  isOpen = true,
  onToggle = () => {},
  backgroundColor = colorPalette.primaryMain1,
  defaultOpenBehavior = true,
}) {
  const [defaultOpen, setOpen] = useState(isOpen);
  const defaultOnChange = keys => {
    const open = keys.length > 0;
    setOpen(open);
    onToggle(open);
  };

  const isSectionOpen = defaultOpenBehavior ? defaultOpen : isOpen;

  const onHeaderPress = e => {
    e.stopPropagation();
    if (e.key === 'Enter') {
      e.preventDefault();
      e.target.blur();
    }
  };

  function renderHeader() {
    return (
      <div className={styles.header}>
        <h5
          className={styles.title}
          contentEditable={adminMode}
          suppressContentEditableWarning={true}
          onBlur={onBlur}
          onKeyDown={onHeaderPress}
        >
          {title}
        </h5>
        {showActions && <ActionsDropdown actions={adminActions} />}
        {customActions}
      </div>
    );
  }

  return (
    <ConfigProvider
      theme={{
        components: {
          Collapse: {
            headerPadding: '8px 16px',
            headerMargin: 0,
            contentPadding: 0,
            headerBg: 'transparent',
            colorBorder: '#bac8f0',
            borderRadius: 4,
            headerBorderRadius: 4,
          },
        },
      }}
    >
      <div className={styles.root}>
        <Collapse
          className={styles.collapse}
          activeKey={isSectionOpen ? ['1'] : []} // Dynamically control open state
          onChange={defaultOnChange} // Triggered when user interacts with the panel
          bordered={false}
          expandIcon={({ isActive }) => (
            <FontAwesomeIcon
              className={styles.icon}
              icon={faCaretRight}
              rotation={isActive ? 90 : 0}
            />
          )}
        >
          <Collapse.Panel key="1" header={renderHeader()}>
            <div className={styles.items} style={{ backgroundColor }}>
              {children}
            </div>
          </Collapse.Panel>
        </Collapse>
      </div>
    </ConfigProvider>
  );
}
