import React from 'react';
import styles from './lab-value-info.module.scss';
import ReactMarkdown from 'react-markdown';
import { Lang } from 'shared/constants';
import { Button } from 'src/components/core';
import { Popup } from 'src/components/common';
import { LabBreadcrumbs } from 'src/components/breadcrumbs/LabBreadcrumbs';
import { TagsIcons } from 'src/components/tags/tagsIcons';
import analyticsService from 'src/helpers/analytics.service';
import { EVENTS } from 'shared/constants';
import { TextTag } from 'src/components/tags/text-tags';
import { Owners } from 'src/components/common/owners';

const DetailInfo = ({ label, value, link }) => {
  if (!value) return false;

  return (
    <div className={styles.detail}>
      <h6>{label}</h6>
      <div className={styles.contentDetails}>
        {!link && value}
        {link && (
          <a href={link} target="_blank" rel="noreferrer">
            {value}
          </a>
        )}
      </div>
    </div>
  );
};

const MrkdwnDetailInfo = ({ label, value }) => {
  if (!value) return false;

  return (
    <div className={styles.detail}>
      <h6>{label}</h6>
      <div className={styles.contentDetails}>
        <ReactMarkdown children={value} />
      </div>
    </div>
  );
};

const LabValueInfoComponent = React.memo(function LabValueInfoComponent({
  id,
  item,
  lang,
  labSetItemDetails,
  parents,
  labSelectValues,
}) {
  const close = React.useCallback(
    ({ id = '', title = '', tags = {}, ownerIds = '' } = {}) => {
      if (id.length > 0) {
        analyticsService.track(EVENTS.LAB_ITEMS.CLOSE_INFORMATION, {
          id: id.toString(),
          title: title,
          ownerIds,
          ...tags,
        });
      }

      labSetItemDetails(null);
    },
    [labSetItemDetails],
  );

  const onNavigateToCategory = id => {
    close();
    labSelectValues(id);
  };

  if (!id) {
    return null;
  }

  let { reference, info, url, tags } = item;

  const renderTags = () => (
    <>
      <div className={styles.tags}>
        <TextTag tag={tags?.contentType} />
        <TagsIcons
          tags={tags}
          hideTags={{ targetAudience: true, contentType: true }}
          className={styles.tagIcons}
        />
      </div>
    </>
  );

  return (
    <Popup isOpen={true} title={lang.HEADER}>
      {renderTags()}
      <div className={styles.data}>
        <Owners
          ownerIds={item.ownerIds}
          title={lang.VALIDATED_BY}
          titleClassName={styles.ownerTitle}
          onNavigate={close}
        />

        <MrkdwnDetailInfo label={lang.INFO} value={info} />

        <DetailInfo
          label={lang.REFERENCE}
          value={reference && lang.REFERENCE_LINK_PLACEHOLDER}
          link={reference}
        />

        <DetailInfo label={lang.URL} value={url && lang.URL_CALCULATOR} link={url} />
      </div>

      <div className={styles.detail}>
        <h6>{lang.PARENTS_PATH}</h6>
        {parents.map(category => (
          <LabBreadcrumbs
            wrapperStyle={styles.breadcrumbs}
            pathParts={category.pathParts}
            title={category.title}
            key={category.id}
            onCurrentClick={() => onNavigateToCategory(category.id)}
            hideRoot
            initialWorkspaceId={category.workspaceId}
          />
        ))}
      </div>

      <Button className={styles.button} onClick={() => close(item)}>
        {lang.DISMISS}
      </Button>
    </Popup>
  );
});

LabValueInfoComponent.defaultProps = {
  itemId: null,
  lang: Lang.LAB_INFORMATION_MODAL,
  item: {},
  owner: {},
  parents: [],
  labSetItemDetails: () => {},
  labSelectValues: () => {},
};

export { LabValueInfoComponent };
