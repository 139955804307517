import React, { useCallback, useMemo } from 'react';
import { RotationsList } from './rotations-list.component';
import { SearchBox } from 'src/components/design-system/forms/search-box.component';
import { useRotationsContext } from '../rotations-context';
import styles from './rotations-list.module.scss';
import { AddNewRotation } from './rotations-list-add-rotations.component';
import { Tabs } from 'src/components/design-system';

const RotationsTabs = () => {
  const {
    lang,
    myRotations,
    mySchedule,
    allRotations,
    filter,
    setTab,
    setFilter,
    tab,
    canEditSettings,
  } = useRotationsContext();

  const filterByName = useCallback(
    ({ name }) => {
      if (!filter?.length) {
        return true;
      }
      return name.toLowerCase().includes(filter.toLowerCase());
    },
    [filter],
  );

  const tabs = useMemo(() => {
    return [
      {
        key: lang.MY_SCHEDULE,
        label: lang.MY_SCHEDULE,
        children: <RotationsList rotations={mySchedule.filter(filterByName)} />,
        shouldRender: mySchedule.length > 0,
      },
      {
        key: lang.MY_ROTATIONS,
        label: lang.MY_ROTATIONS,
        children: <RotationsList rotations={myRotations.filter(filterByName)} />,
        shouldRender: myRotations.length > 0,
      },
      {
        key: lang.ALL_ROTATIONS,
        label: lang.ALL_ROTATIONS,
        children: <RotationsList rotations={allRotations.filter(filterByName)} />,
        shouldRender: allRotations.length > 0,
      },
    ];
  }, [lang, allRotations, mySchedule, myRotations, filterByName]);

  const hasData = myRotations.length > 0 || mySchedule.length > 0 || allRotations.length > 0;

  if (!hasData && !canEditSettings) {
    return null;
  }

  return (
    <div className={styles.viewSelector}>
      {hasData && (
        <>
          <SearchBox
            size="large"
            value={filter}
            onChange={e => setFilter(e.target.value)}
            placeholder={lang.FILTER_PLACEHOLDER}
            allowClear
          />

          <Tabs
            defaultActiveKey={tab || lang.MY_SCHEDULE}
            items={tabs.filter(({ shouldRender }) => shouldRender)}
            onChange={setTab}
          />
        </>
      )}
      {canEditSettings && (
        <div className={styles.addRotationWrapper}>
          <AddNewRotation />
        </div>
      )}
    </div>
  );
};

export { RotationsTabs };
