import { notification } from 'antd';
import { useState, useEffect } from 'react';
import userServiceApi from 'shared/api/user.service.api';

/**
 * Get the professional profile context - preferences and resources
 * @returns {{
 *  contextHolder: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
 *  preferences: any[];
 *  resources: any[];
 *  fetching: boolean;
 *  addUserPreference: (function({ preference: any }): Promise<void>);
 *  updateUserPreference: (function({ preferenceId: string, preference: any }): Promise<void>);
 *  deleteUserPreference: (function({ preferenceId: string }): Promise<void>);
 * }}
 */
export function useProfessionalProfile({ userId }) {
  const [preferences, setPreferences] = useState([]);
  const [resources, setResources] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [api, contextHolder] = notification.useNotification({ placement: 'bottomRight' });

  const showErrorMessage = message => {
    api.error({
      message,
    });
  };

  useEffect(() => {
    const fetchProfessionalProfile = async () => {
      setFetching(true);
      const { data } = await userServiceApi.getUserProfessionalProfile({ userId });

      setPreferences(data.preferences || []);
      setResources(data.resources || []);
      setFetching(false);
    };

    fetchProfessionalProfile();
  }, [userId]);

  const addUserPreference = async ({ preference }) => {
    const { error, data } = await userServiceApi.addUserPreference({ userId, preference });

    if (error) {
      showErrorMessage(data.message);
      return;
    }

    setPreferences(data);
  };

  const updateUserPreference = async ({ preferenceId, preference }) => {
    const { error, data } = await userServiceApi.updateUserPreference({
      userId,
      preferenceId,
      preference,
    });

    if (error) {
      showErrorMessage(data.message);
      return;
    }

    setPreferences(data);
  };

  const deleteUserPreference = async ({ preferenceId }) => {
    const { error, data } = await userServiceApi.deleteUserPreference({ userId, preferenceId });

    if (error) {
      showErrorMessage(data.message);
      return;
    }

    setPreferences(prev => prev.filter(p => p.id !== preferenceId));
  };

  return {
    contextHolder,
    preferences,
    resources,
    fetching,
    addUserPreference,
    updateUserPreference,
    deleteUserPreference,
    // TODO: Add resources management functions
  };
}
