import { formatFullName } from '../../utils';

export const getOwnerById = ({ items }, { id }) => {
  return items[id] || {};
};

export const getOwnersListForEditor = ({ items }) => {
  return Object.values(items).map(({ id, inactive = false, ...owner }) => ({
    id,
    value: formatFullName(owner, true),
    inactive,
  }));
};

export const getOwnersList = ({ items }) => {
  return Object.values(items);
};

export const getOwnersByIDs =
  ownerIds =>
  ({ owners }) =>
    ownerIds?.map(id => owners?.items?.[id] || null).filter(Boolean);

export const isCurrentUserValidOwner = ({ owners, auth }) => {
  const currentUser = auth?.user;

  return !!owners?.items[currentUser?.id];
};
